import { CategoryLocationTabs, Container, MobileGolfClubModelSearch, Sections } from '@components'
import { useModal, useUrlSearchParams, useWindowSize } from '@hooks'
import { HomePage } from '@pages/components'
import { RouteComponentProps } from '@reach/router'
import { AccountSetupModalState, CacheUser, ResponseType } from '@types'
import React, { memo, useEffect, useRef } from 'react'
import { useQuery } from '@apollo/client'
import { user } from '@graphql'
import './home.scss'

interface HomeProps extends RouteComponentProps {}

const Home: React.FC<HomeProps> = props => {
  const { toRender: isMobileScreen } = useWindowSize(['mobile', 'landscape'])
  const blockForScrollRef = useRef<HTMLDivElement>(null)
  const { getUrlSearchParam: getUrlSearchAuthParam } = useUrlSearchParams('authorization')
  const urlAuth = getUrlSearchAuthParam()
  const { getUrlSearchParam: getUrlSearchCategoryParam } = useUrlSearchParams('categoryIds')
  const urlCategory = getUrlSearchCategoryParam()
  const { getUrlSearchParam: getUrlForgotPasswordParam } = useUrlSearchParams('forgot-password')
  const forgotPasswordParam = getUrlForgotPasswordParam()
  const { openModal } = useModal<AccountSetupModalState>('AccountSetupModal')

  const { data: currentUserData, loading } = useQuery<ResponseType<CacheUser>>(user.FetchCurrentUser, {
    fetchPolicy: 'network-only'
  })

  const isLogin = currentUserData?.res.id

  useEffect(() => {
    const token = localStorage.getItem('token')

    if (forgotPasswordParam) {
      openModal({ part: 'ForgotPassword' })
    }

    if (urlAuth) {
      localStorage.setItem('token', urlAuth)

      window.location.reload()
    }
  }, [])

  useEffect(() => {
    if (urlCategory && urlCategory.length) {
      const relativeBlockPosition = blockForScrollRef.current?.getBoundingClientRect().y
      window.scrollBy(0, relativeBlockPosition || 0)
    }
  }, [urlCategory])

  return (
    <section className="home-container">
      <HomePage.Hero />
      {isMobileScreen && (
        <div className="home-mobile-searchbar">
          <MobileGolfClubModelSearch />
        </div>
      )}
      <Container>
        <div ref={blockForScrollRef} />
        <Sections />
        <CategoryLocationTabs />

        {/*<BrandsListSection
          noItemMessage={'Not found'}
          title={'Popular Brands'}
          link={{
            label: 'All',
            value: 'POPULAR_BRANDS'
          }}
          categoryId={urlCategory}
        />*/}
        {/*<GolfClubModelsListSection
          sortBy={'MOST_POPULAR_BRANDS'}
          noItemMessage={'Not found'}
          title={'Most Popular'}
          link={{
            label: 'All',
            value: 'MOST_POPULAR_BRANDS'
          }}
        />*/}
        {/*<HomePage.ProfessionalsChoice />*/}
        <HomePage.StarterSet />
        {/* <GolfClubModelsListSection
          sortBy={'LOWEST_PRICE'}
          noItemMessage={'Not found'}
          title={'New Lowest Price'}
          link={{
            label: 'All',
            value: 'LOWEST_PRICE'
          }}
        />*/}
      </Container>
    </section>
  )
}

export default memo(Home)
